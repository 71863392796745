import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'

import Layout from '@/components/Layout'
import { NextSeo } from 'next-seo'
import { getStorefrontSettings } from '@/utils/cms'
import { StorefrontSettingsContext } from '@/stores'

export async function getStaticProps({ preview }: GetStaticPropsContext) {
  const storefrontSettings = await getStorefrontSettings()
  return {
    props: {
      storefrontSettings,
    },
  }
}

// TODO: Use a better 404 page from tailwindui
const NotFound: React.FC<InferGetStaticPropsType<typeof getStaticProps>> = ({
  storefrontSettings,
}) => {
  return (
    <StorefrontSettingsContext.Provider value={storefrontSettings}>
      <Layout>
        <div className={'container'}>
          <div className="flex flex-col items-center justify-center max-w-2xl py-20 mx-8 sm:mx-auto fit">
            <h2 className={'font-bold text-6xl mb-4'}>Not Found</h2>
            <div className="">
              Uh oh, looks like the page you are looking for has moved or no
              longer exists.
            </div>
          </div>
        </div>
      </Layout>
      <NextSeo title={'PAGE NOT FOUND'} />
    </StorefrontSettingsContext.Provider>
  )
}

export default NotFound
